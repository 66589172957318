<template>
  <div>
    <Report module="Financeiro" name="Receita x Despesa de Locação" urlGet="/api/v1/finance/report/rent-performance"
      :header="header" :filters="filters" />

    <Modal title="Despesa" :width="1100" :height="550" v-if="showModal('expense')">
      <Loading :center="false" v-show="loading" />
      <SubReport v-show="!loading" :titleHeader="subReport.title" :minHeight="500" :header="subReport.fields"
        :data="subReport.data" :totalizationCustom="subReport.totalization" />

    </Modal>
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";
import SubReport from "@nixweb/nixloc-ui/src/component/shared/Report.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ReportRentPerformance",
  components: {
    Report, SubReport, Modal, Loading
  },
  data() {
    return {
      urlGetSubReportApi: "/api/v1/finance/relationship-rent/report-by-rent",
      header: [
        {
          title: "Dt Cadastro",
          field: "registeredDateStr",
          fieldOrderBy: "RegisteredDate",
          show: true,
          styleHeader: "width:100px;",
          styleBody: "max-width: 100px;",
        },
        {
          title: "Dt Efetivação",
          field: "dateAcceptedStr",
          fieldOrderBy: "DateAccepted",
          show: false,
          styleHeader: "width:100px;",
          styleBody: "max-width: 100px;",
        },
        {
          title: "Número",
          field: "number",
          fieldOrderBy: "Number",
          show: true,
          initialOrderBy: true,
        },
        {
          title: "Identificação",
          field: "identification",
          fieldOrderBy: "Identification",
          show: true,
        },
        {
          title: "Razão Social/Nome",
          field: "companyName",
          fieldOrderBy: "CompanyName",
          show: true,
          styleHeader: "width:250px;",
          styleBody: "max-width: 240px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Consultor",
          field: "collaboratorName",
          fieldOrderBy: "CollaboratorName",
          show: false,
          styleHeader: "width:150px;",
          styleBody: "max-width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Status",
          field: "statusRentalName",
          fieldOrderBy: "StatusRentalName",
          show: false,
          styleHeader: "width:100px;",
          styleBody: "max-width: 90px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "R$ Receita",
          field: "revenueStr",
          fieldOrderBy: "Revenue",
          show: true,
        },
        {
          title: "R$ Despesa",
          field: "expenseStr",
          fieldOrderBy: "Expense",
          eventName: "expense",
          show: true,
          styleHeader: "max-width:150px;",
          styleBody: "max-width: 150px; white-space: nowrap;cursor:pointer;text-decoration: underline;",
        },
        {
          title: "% Margem",
          field: "percentage",
          fieldOrderBy: "Percentage",
          show: true,
        },
      ],
      filters: [
        {
          type: "date",
          title: "Dt Cadastro",
          field: "RegisteredDate",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Dt Efetivação",
          field: "DateAccepted",
          value: "",
          required: true,
        },
        {
          type: "text",
          title: "Número",
          field: "Number",
          value: "",
        },
        {
          type: "multiOptions",
          title: "Cliente",
          field: "CompanyName",
          url: "/api/v1/crm/customer/select-all-without-document",
          propsParams: { isCustomer: true, isSupplier: false },
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusRentalName",
          options: [
            { text: "Orçamento", value: "Orçamento" },
            { text: "Aprovada", value: "Aprovada" },
            { text: "Reprovada", value: "Reprovada" },
          ],
          value: [],
        },
        {
          type: "decimal",
          title: "R$ Despesa",
          field: "Revenue",
          valueStart: 0,
          valueEnd: 0,
        },
        {
          type: "decimal",
          title: "R$ Receita",
          field: "Expense",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
      subReport: {
        title: "",
        fields: [
          {
            field: "recipientName",
            title: "Destinatário",
            type: "text",
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
            styleHeader: "width:250px;",
            styleBody: "max-width: 250px; white-space: nowrap;",
          },
          {
            field: "dueDate",
            title: "Dt Vencimento",
            type: "date",
          },
          {
            field: "general",
            title: "Valor",
            type: "currency",
          },
        ],
        data: [],
        totalization: [],
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["openModal"]),
    getAll(number) {
      this.loading = true;
      let params = {
        url: this.urlGetSubReportApi,
        obj: { id: number },
      };
      this.getApi(params).then((response) => {
        this.subReport.data = response.content.data;
        this.subReport.totalization = response.content.totalization;
        this.loading = false;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "expense") {
          this.subReport.title = `Locação Nº ${event.data.number} - ${event.data.companyName}`;
          this.getAll(event.data.number);
          this.openModal("expense");
        }
      },
      deep: true,
    },
  },
}


</script>